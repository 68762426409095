import React, { useEffect, useLayoutEffect } from 'react';
import Loader from "../includes/Loader";
import { requestGetHTTP } from "../helpers/helper";

function UnSubscribed() {

    useLayoutEffect(() => {
        document.title = 'Unsubscribe | ESG'; // title
    }, [])
    useEffect(() => {
        async function fetchProfileData() {
            let getProfile = JSON.parse(localStorage.getItem("profileData"));
            if (!getProfile) {
                getProfile = await requestGetHTTP('/v1/user/profile');
            }
            return getProfile.phone;
        }

        fetchProfileData().then(msisdn => {
            localStorage.removeItem('token');
            if (msisdn) {
                unSubscribeEncodeData(msisdn);
            }
        });

        const unSubscribeEncodeData = async (mobile) => {
            try {
                if (mobile) {
                    const domainName = 'ufone_esg';
                    const response = await fetch(`${process.env.REACT_APP_UNSUB_PORTAL_URL}/api/encrypt`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ mobile, domainName }),
                    });
                    const data = await response.json();
                    if (data.status === 'success' && data.data.mobile && data.data.mobile) {
                        window.location.replace(process.env.REACT_APP_UNSUB_PORTAL_URL + "/" + data.data.mobile + "/" + data.data.domainName);
                    }
                }
            } catch (error) {
                console.error('Error fetching while unsubscribe:', error.message);
            }
        };

    }, []);

    return (
        <Loader />
    );
}

export default UnSubscribed;
