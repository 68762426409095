import React, { useEffect, useState } from 'react';
import '../../assets/css/tournament.css';
import { requestPostHTTP } from "../helpers/helper";
import Loader from "../includes/Loader";
import { NavLink } from "react-router-dom";

function CompletedTaskPage() {
    const [completedTask, setCompletedTask] = useState([]);
    const [notCompletedTask, setNotCompletedTask] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = 'Completed Task | ESG'; // title
        async function fetchReqData() {
            try {
                const getCompletedTask = await requestPostHTTP('/v1/completed-task/get');
                if (getCompletedTask.data.length > 0) {
                    setCompletedTask(getCompletedTask.data);
                } else {
                    setNotCompletedTask(true);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        fetchReqData();

    }, []);

    return (
        <section>
            <div className="cards-wrapper-tournament col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tournament-heading">
                    <h2 className="headingz mb-4">Completed Tasks</h2>
                    <p className="tournament-sub-heading">Check Your Completed Tasks</p>
                </div>

                <div className="row">
                    {loading ? (
                        <Loader />
                    ) : (
                        completedTask.length > 0 && completedTask.map((completedTask, index) => (
                            <div key={index} className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <div className="cards-tournament-round">
                                    <img src={completedTask.thumbnail} alt="completedTask-img" style={{ minHeight: "400px", minWidth: "250px", margin: "0 auto" }} />
                                    <div className="tournament-card-info">
                                        <div className="row">
                                            <div className="col-8">
                                                <h6 className="tournament-info-heading">{completedTask.objective}</h6>
                                                <p className="card-heading-tournament">Win {completedTask.prize} Credits</p>
                                            </div>
                                            {(completedTask.makeEntry === 0 || completedTask.makeEntry === 2) ?
                                                <div className="col-4">
                                                    <NavLink className="submit-again" to={`/submit-entry/${completedTask.game_uuid}/${completedTask.challenge_uuid}/0`}>
                                                        Submit Again
                                                    </NavLink>
                                                </div>
                                                : (<div className="col-4">
                                                    <NavLink className="play-btn"
                                                        to={`/entry-details/${completedTask.game_uuid}/${completedTask.challenge_uuid}`}>
                                                        View Details
                                                    </NavLink>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}

                    {notCompletedTask && (
                        <div className="d-flex justify-content-center align-items-center no-data-found">
                            <p>No completed tasks found</p>
                        </div>
                    )}

                </div>
            </div>
        </section>
    );
}

export default CompletedTaskPage;