import React, { useLayoutEffect, useState } from 'react';
import styles from '../../../assets/css_modules/redeem/redeemForm.module.css';
import { claimRedeem } from "../../../ApiCalls/Redeem/api_v1_redeem";
import { useNavigate } from "react-router-dom";
import ThankYou from './ThankYou';
function RedeemForm(props) {
    let navigate = useNavigate();
    const [showThankyou, setShowThankyou] = useState(false);
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState(props.showForm.tel);
    const [email, setEmail] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [insufficentCoinsMsg, setInsufficentCoinsMsg] = useState(false);
    let showForm = props.showForm;
    useLayoutEffect(() => {
        document.title = 'Redeem Form | ESG'; // title
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
    }, []);
    const handleSubmit = (e) => {
        // console.log(e)
        setBtnDisabled(true);
        let btn = document.getElementById("submit");
        btn.innerHTML = "Please Wait";
        btn.style.backgroundColor = "#db7119";
        e.preventDefault();
        // alert(`The name you entered was: ${name}`)
        let claimData = {
            item: showForm.item,
            uuid: showForm.uuid,
            phone: mobile,
            email: email,
            name: name
        }
        claimRedeem(claimData).then(response => {
            if (response.data.data.message === "Ko" && response.status === 200) {
                setInsufficentCoinsMsg(true);
                setTimeout(() => {   // ***
                    setMessage();  // *** If you want to clear the error message as well
                }, 1500);
            }
            else if (response.data.data.message === "ok" && response.status === 200) {
                setShowThankyou(true)
            }


        }).catch(error => {
            console.log(error);
        });

        function setMessage() {
            btn.style.backgroundColor = "rgb(61, 60, 58)"; btn.innerHTML = "redeem"; setInsufficentCoinsMsg(false); setBtnDisabled(false);
        }
    }

    return (
        <>
            {showThankyou ? <ThankYou /> :
                <main className={styles.form_main}>
                    <section className={`${styles.insufficentCoins} ` + (insufficentCoinsMsg ? `${styles.show}` : `${styles.hidden}`)}>
                        <p>Insufficent Coins</p>
                    </section>
                    <section className={styles.formSection}>
                        <h1 className={styles.formSectionh3}>Congratulations!</h1>
                        <p className={styles.formSectionp1}>You can redeem your ESG Coins to purchase</p>
                        <p className={styles.formSectionp2}>{showForm.key}</p>
                        <p className={styles.formSectionp3}>Please Enter Your Details Below</p>
                    </section>
                    <section className={styles.formWrap}>
                        <form onSubmit={handleSubmit} className={styles.RedeemForm}>
                            <div>
                                <p className='text-black mb-2'>Name</p>
                                <input
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Name"
                                    className={styles.formInput}
                                    required={true}
                                    autoFocus={true}
                                    minLength="3"
                                    maxLength="50"
                                />
                            </div>
                            <div className={styles.mtb}>
                                <p className='text-black mb-2'>Mobile Number</p>
                                <input
                                    type="text"
                                    onChange={(e) => setMobile(e.target.value)}
                                    placeholder="Mobile Number"
                                    className={`${styles.formInput} `}
                                    required={true}
                                    readOnly={true}
                                    defaultValue={props.showForm.tel}
                                    minLength="11"
                                    maxLength="11"
                                    onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                />
                            </div>
                            <div>
                                <p className='text-black mb-2'>Email</p>
                                <input
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                    className={styles.formInput}
                                    required={true}
                                    minLength="14"
                                    maxLength="50"
                                />
                            </div>
                            <button type="submit" id='submit' value="Redeem" className={styles.RedeemFormBtn} disabled={btnDisabled}>Redeem</button>
                        </form>
                    </section>
                </main>
            }
        </>
    )
}

export default React.memo(RedeemForm);