import React from 'react';
import '../../assets/css/footer.css';
import xIcon from '../../assets/images/icons/x.webp';
import youtubeIcon from '../../assets/images/icons/youtube.svg';
import logo from '../../assets/images/logo.png';

function Footer() {
    return (
        <footer className="footer" id="footer">
            <div className="row align-items-center">
                <div className="col-md-2 col-6 col-sm-6  text-lg-left footer-logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className="col-6 col-sm-6 footer-social-links footer-card mobile-social-links">
                    <span className="follow-text">Follow Us</span>
                    <img className="img-fluid mx-2" src={xIcon} alt="x-icon" />
                    <img className="img-fluid mx-2" src={youtubeIcon} alt="Youtube-icon" />
                </div>
                <div className="col-md-7 col-sm-12 footer-left">
                    <p className="mb-4 mb-md-0">
                        Unleash Your eSports potential with ESG<br />
                        © 2024 All Rights Reserved</p>
                </div>
                <div className="col-md-3 col-6 footer-social-links footer-card desktop-social-links">
                    <span className="follow-text">Follow Us</span>
                    <img className="img-fluid mx-2" src={xIcon} alt="x-icon" />
                    <img className="img-fluid mx-2" src={youtubeIcon} alt="Youtube-icon" />
                </div>
            </div>
        </footer>
    );
}

export default Footer;
